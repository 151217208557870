import * as React from 'react';
import Arrow from './asset/arrow.svg';
import * as Styles from '../../styles/components/_breadcrumb.module.scss';
import {Link} from "gatsby-plugin-react-intl"

export const BreadCrumb = ({ list, currentPage }) => {
  return (
    <div className={Styles.breadcrumb}>
      <ul className={Styles.breadcrumb__list}>
        {list.map(({ title, link }, i) => (
          <li key={i}>
            {link ? <Link to={link}>{title}</Link> : <span>{title}</span>}
            <img src={Arrow} />
          </li>
        ))}
        <li>{currentPage}</li>
      </ul>
    </div>
  );
};

export default BreadCrumb;
