import * as React from 'react';
import { graphql } from 'gatsby';
import * as Styles from '../styles/pages/_history.module.scss';
import { Layout } from '../components/layout';
import { Seo } from '../components/seo';
import { dayjs } from '../lib/dayjs';
import { BackGround } from '../components/background';
import { BreadCrumb } from '../components/breadcrumb';
import {Link, useIntl} from "gatsby-plugin-react-intl"

const HistoryPage = ({ data, location }) => {
  const intl = useIntl()
  const t = (id) => intl.formatMessage({id})
  // 数字の頭の0を削除する
  const suppressZero = (str) => {
    let idx = 0;
    while (str.charAt(idx) === '0') idx++;
    return str.slice(idx);
  };

  const historyReordering = (data) => {
    // 配列から年の一覧を抽出
    const years = Array.from(new Set(data.map(({ node }) => dayjs(node.date).format('YYYY'))));

    const yearsSort = [];
    years.forEach((year) => {
      // 年ごとに要素を抽出して配列を作成
      const yearArray = data.filter(({ node }) => dayjs(node.date).format('YYYY') === year);

      // 配列から月の一覧を抽出
      const months = Array.from(new Set(yearArray.map(({ node }) => dayjs(node.date).format('MM'))));

      const monthSort = [];
      months.forEach((month) => {
        // 月ごとに要素を抽出して配列を作成
        const monthArray = yearArray.filter(({ node }) => dayjs(node.date).format('MM') === month);

        monthSort.push({
          month: suppressZero(month),
          items: monthArray,
        });
      });

      yearsSort.push({
        year: year,
        items: monthSort,
      });
    });

    return yearsSort;
  };

  const historyItems = historyReordering(data.allMicrocmsHistory.edges);

  return (
    <Layout location={location}>
      <Seo title={t('history.seo.title')} description={t('history.seo.description')} />
      <BreadCrumb
        list={[
          {
            title: 'TOP',
            link: '/',
          },
          {
            title: t('menu.firstLIMITS'),
          },
        ]}
        currentPage={t('menu.soFarLIMITS')}
      />
      <BackGround>
        <section className={Styles.history}>
          <div className={Styles.history__title}>
            <h1>{t('menu.soFarLIMITS')}</h1>
          </div>
          <div>
            <ul className={Styles.year_list}>
              {historyItems.length > 0 ? (
                historyItems.reverse().map(({ year, items }) => (
                  <li>
                    <h2>{year}</h2>
                    <ul className={Styles.month_list}>
                      {items.reverse().map(({ month, items }) => (
                        <li>
                          <h3>{`${month}月`}</h3>
                          <ul className={Styles.event_list}>
                            {items.map(({ node }) => (
                              <li key={node.historyId}>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: node.title,
                                  }}
                                />
                              </li>
                            ))}
                          </ul>
                        </li>
                      ))}
                    </ul>
                  </li>
                ))
              ) : (
                <li>{t('history.nodata')}。</li>
              )}
            </ul>
          </div>
        </section>
      </BackGround>
    </Layout>
  );
};

export const query = graphql`
  query {
    allMicrocmsHistory(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          historyId
          date
          title
        }
      }
    }
  }
`;

export default HistoryPage;
