import classNames from 'classnames';
import * as React from 'react';

export const BackGround = ({ children }) => {
  return (
    <>
      <div>
        {children}
      </div>
    </>
  );
};
export default BackGround;
